import { useQuery } from '@vue/apollo-composable'
import { ref } from 'vue'

import { CheckUserIsValidDocument } from '@/graphqlTypes'

export const useCheckUserIsValid = () => {
  const isNotAuthorized = ref(false)

  const { loading, error, refetch, onResult, onError } = useQuery(
    CheckUserIsValidDocument,
    {},
    { errorPolicy: 'none' },
  )

  onResult(({ data }) => {
    isNotAuthorized.value = !data?.me.valid
  })
  onError(({ graphQLErrors }) => {
    isNotAuthorized.value = graphQLErrors[0] && graphQLErrors[0].errorType === 'Unauthorized'
  })

  return { loading, error, refetch, isNotAuthorized }
}
