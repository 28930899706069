import { Auth, CognitoUser } from '@aws-amplify/auth'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

import { usePostHog } from '@/plugins/usePostHog'

import { useNotification } from './notification'

interface IState {
  email?: string
  full_name?: string
  name?: string
  family_name?: string
  tenantId?: string
}

/*
 * Custom attributes type defined according to the attributes used in this app
 */
export interface UserAttributes {
  sub: string
  email: string
  password: string
  email_verified: string
  code: string
  name: string
  family_name: string
  updated_at: string
  'custom:tenantId': string
}

/*
 * The following interface extends the CognitoUser type because it has issues
 * (see github.com/aws-amplify/amplify-js/issues/4927). Eventually (when you
 * no longer get an error accessing a CognitoUser's 'attribute' property) you
 * will be able to use the CognitoUser type instead of CognitoUserExt.
 */
interface CognitoUserExt extends CognitoUser {
  attributes: UserAttributes
}

export const useUser = defineStore('user', () => {
  const state: IState = {}

  const { posthog } = usePostHog()

  const { error, success } = useNotification()

  function set(attr: UserAttributes) {
    state.email = attr.email
    state.full_name = attr.name + ' ' + attr.family_name
    state.name = attr.name
    state.family_name = attr.family_name
    state.tenantId = attr['custom:tenantId'] ?? ''
  }

  async function signInCaller({ email, password }: UserAttributes) {
    Auth.configure({ authenticationFlowType: 'USER_PASSWORD_AUTH' })
    const user: CognitoUserExt = await Auth.signIn(email, password)
    set(user.attributes)
    identifyUser(user.attributes)
    return user
  }

  async function checkUser() {
    try {
      const user: CognitoUserExt = await Auth.currentAuthenticatedUser()
      set(user.attributes)
      identifyUser(user.attributes)
      return true
    } catch (error) {
      return false
    }
  }

  const signUpCaller = async ({ email, password }: UserAttributes) => {
    const user = await Auth.signUp({
      username: email,
      password,
    })
    success({
      title: 'Account created',
      desc: `We have sent you a code to your email ${email} to verify your account`,
    })
    return user
  }

  async function confirmSignUpCaller({ email, code }: UserAttributes) {
    await Auth.confirmSignUp(email, code.toString())
    success({ title: 'Account verified' })
    return true
  }

  async function signOutCaller() {
    await Auth.signOut({ global: true })
    success({ title: 'You have been successfully logged out.' })
    return true
  }

  async function forgotPasswordCaller(email: string) {
    await Auth.forgotPassword(email)
    success({
      title: 'Code sent to verify account',
      desc: `Check your '${email}' inbox or spam folder.`,
    })
    return true
  }

  async function updatePasswordCaller({ email, code, password: new_password }: UserAttributes) {
    await Auth.forgotPasswordSubmit(email, code, new_password)
    success({ title: 'Your password has been updated.' })
    return true
  }

  async function updateEmailCaller(email) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      email,
    })
    success({ title: 'Code sent to verify your email', desc: `Check your inbox at ${email}` })
    return true
  }

  async function verifyAttributesSubmitCaller(code: string) {
    await Auth.verifyCurrentUserAttributeSubmit('email', code)
    success({ title: 'Email successfully updated' })
    return true
  }

  async function changePasswordCaller({ oldPass, newPass }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPass, newPass)
    success({ title: 'Password successfully updated' })
    return true
  }

  function identifyUser({ name, email, 'custom:tenantId': tenantId }: UserAttributes) {
    if (window.Beacon) {
      window.Beacon('identify', { name, email })
    }
    if (window.profitwell) {
      window.profitwell('user_email', email)
    }

    if (!posthog._isIdentified()) {
      posthog.identify(tenantId, { name, email, userId: tenantId })
    }

    Sentry.setUser({ name, email, id: tenantId })
  }

  async function resendSignUpCaller({ email }) {
    await Auth.resendSignUp(email)
    success({
      title: 'Code sent to verify your account',
      desc: `Check your inbox or spam folder for ${email}`,
    })
    return true
  }

  async function addAccountCaller(userId: string) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      'custom:tenantId': userId,
    })
    user.attributes['custom:tenantId'] = userId
    set(user.attributes)

    success({
      title: 'Account linked correctly',
      desc: `Your Flickr account [${userId}] has been linked.`,
    })
    return true
  }

  async function tryCatchWrapper(fn, params = {}) {
    try {
      return await fn(params)
    } catch ({ code: title, message: desc }) {
      error({ title, desc })
      return false
    }
  }

  async function signIn(params: UserAttributes) {
    return await tryCatchWrapper(signInCaller, params)
  }

  async function signUp(params: UserAttributes) {
    return await tryCatchWrapper(signUpCaller, params)
  }

  async function confirmSignUp(params: UserAttributes) {
    return await tryCatchWrapper(confirmSignUpCaller, params)
  }

  async function signOut() {
    return await tryCatchWrapper(signOutCaller)
  }

  async function forgotPassword(params: UserAttributes) {
    return await tryCatchWrapper(forgotPasswordCaller, params)
  }

  async function updatePassword(params: UserAttributes) {
    return await tryCatchWrapper(updatePasswordCaller, params)
  }

  async function updateEmail(params: UserAttributes) {
    return await tryCatchWrapper(updateEmailCaller, params)
  }

  async function verifyAttributesSubmit(params: UserAttributes) {
    return await tryCatchWrapper(verifyAttributesSubmitCaller, params)
  }

  async function changePassword(params: UserAttributes) {
    return await tryCatchWrapper(changePasswordCaller, params)
  }

  async function resendSignUp(params: UserAttributes) {
    return await tryCatchWrapper(resendSignUpCaller, params)
  }

  async function addAccount(params: UserAttributes) {
    return await tryCatchWrapper(addAccountCaller, params)
  }

  return {
    state,
    signIn,
    signOut,
    confirmSignUp,
    checkUser,
    signUp,
    resendSignUp,
    addAccount,
    changePassword,
    verifyAttributesSubmit,
    forgotPassword,
    updatePassword,
    updateEmail,
  }
})
