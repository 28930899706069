import { ref, shallowRef, watch } from 'vue'
import { useRouter } from 'vue-router'

import TheAppLayout from '../components/layouts/TheAppLayout.vue'
import TheAuthLayout from '../components/layouts/TheAuthLayout.vue'
import { useCheckUserIsValid } from './useCheckUser'

export const AppLayout = 'AppLayout'
export const AuthLayout = 'AuthLayout'

const LAYOUTS = {
  [AppLayout]: TheAppLayout,
  [AuthLayout]: TheAuthLayout,
}

const showMenu = ref(false)

export const useLayout = () => {
  const router = useRouter()

  const layout = shallowRef('div')
  router.afterEach((to) => {
    layout.value = LAYOUTS[to.meta.layout]
    showMenu.value = to.path.startsWith('/acc')
  })

  const { isNotAuthorized } = useCheckUserIsValid()

  watch(isNotAuthorized, () => {
    showMenu.value = !isNotAuthorized.value
  })

  return { layout, showMenu }
}
